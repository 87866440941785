<style scoped>
	.cntnr {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
</style>
<template>
	<v-progress-circular
		class="cntnr"
		color="primary"
		size="96"
		indeterminate
	/>
</template>
<script>
	export default {
		name: "Logout",
		mounted() {
			const that = this;
			window.setTimeout(function () {
				that.logout();
			}, 1500);
		},
		methods: {
			logout() {
				localStorage.setItem("webSessionId", 0);
				localStorage.setItem("webSessionDayOfYear", 0);
				window.location.reload();
			},
		},
	};
</script>
